<template>
  <div style="max-width:100%;" class="d-flex flex-column tab">
    <div class="tab-inner mx-auto iPhoneBck">
      <div class="d-flex align-items-center mdfont-resp justify-content-center" style="font-style:italic;width:100%; height:40px; color:#fff; background:#333; z-index:10">
        <div v-if="isPractiseQuestion">
          <span>Practice Question</span>
        </div>
        <div v-else>
          <span v-if="selectedQuestion.ScenarioTitle === 'N/A'">Question {{ numberOfAnswerableQuestionsBeforeSelected }} of {{ numberOfAnswerableQuestions }}</span>
          <span v-else>Scenario</span>
        </div>
      </div>
      <!--unanswered candidates for mobile view-->
    
      <!--video panel-->
      <div style="height:calc(60% - 20px);position:relative;background:black" class="d-flex align-items-center justify-content-center vidH">
        <Playback v-bind="{ stopButtonPressed, isPractiseQuestion, iOS }" />	
        <div v-if="job.AudioOnly === 0">
          <div v-if="!viewingQuestionVideo">
            <div
              v-if="!cameraOn"
              style="height:100%;width:100%;position:absolute;background:black;z-index: 999;color:white;padding:20px;left:0;top:0"
              class="d-flex align-items-center justify-content-center vidH"
            >
              <div style="text-align: center;">
                <i class="fas fa-eye-slash mr-2" style="font-size: 6rem;" />
                <div style="font-size: smaller;margin-top: 30px !important;">
                  Your face is hidden for you, but please note, your camera will still be recording video.
                </div>
              </div>
            </div>	          
            <div
              v-if="cameraOn"
              style="cursor: pointer;color: white;margin-left: 8px;z-index: 1002;"
            >
              <i
                class="fas fa-eye-slash mr-2 eyeEye"
                style="position: absolute;bottom: 13px;right: 5px;z-index: 1002;"
                title="Hide my face!" 
                alt="Hide my face!" 
                @click="showHideMyCamera()"
              />
            </div>
            <div
              v-else
              style="cursor: pointer;color: white;margin-left: 8px;z-index: 1002;width: 100%;"
            >
              <i
                class="fas fa-eye mr-2 eyeEye"
                style="position: absolute;bottom: 13px;right: 5px;z-index: 1002;"
                title="Show my face!" 
                alt="Show my face!" 
                @click="showHideMyCamera()"
              />
            </div>
          </div>
        </div>

        <!--pre-recording-->
        <video
          v-show="viewingQuestionVideo"
          id="videoQuestion"
          ref="videoQuestion"
          playsinline
          controls
          :style="{'pointer-events': freezeVideoQuestionPointerEvents ? 'none' : 'auto'}"
          controlsList="nodownload"
          class="video-fluid z-depth-1 mx-auto"
          type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
          style="outline:none;margin: 0px;border-radius: 2px;height:100%;object-fit: cover; position:absolute;top:0px;width: 100%"
        />       
        <!--recording-->
        <video
          v-show="!stopButtonPressed && !viewingQuestionVideo"
          id="video"
          ref="video"
          muted="muted"
          type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
          controlsList="nodownload"
          autoplay
          playsinline
          class="video-fluid z-depth-1 mx-auto"
          style="outline:none;margin: 0px;border-radius: 2px;height:100%;object-fit: cover; position:absolute;top:0px;width: 100%"
          :class="{ 'fullW' : iOS }"
        />
        <!--recording stopped-->
        <video
          v-show="((stopButtonPressed && !viewingQuestionVideo) || (stopButtonPressed && !viewingQuestionVideo && isPractiseQuestion)) && (job.DisablePlayback === false || isPractiseQuestion)" 
          id="videoRec"
          ref="videoRec"
          preload="metadata"
          controls
          playsinline
          controlsList="nodownload"
          type="video/mp4; codecs=avc1.42E01E, mp4a.40.2, H.264"
          class="video-fluid z-depth-1 mx-auto"
          style="outline:none;margin: 0px;border-radius: 2px;height:100%;object-fit: cover; position:absolute;width: 100%;"
          :class="{ 'audioOnly': job.AudioOnly === 1, 'audioOnlyPosition' : job.AudioOnly === 1 && iOS === false, 'fullW' : iOS }"
        />-
        <div
          v-if="mobileView && !recordStart && job.AudioOnly === 0"
          style="position:absolute; top:-30px; right:5px;color:white;z-index: 1002;"
          class="d-flex align-items-center mdfont-resp font-weight-bold"
          @click="toggleCamera()"
        >
          <i
            class="fas fa-camera mr-2"
            title="Toggle front/rear camera"
            alt="Toggle front/rear camera" 
          />
        </div>
        <!--recording icon-->
        <div
          v-if="!stopButtonPressed && recordStart"
          style="position:absolute; bottom:0vw;text-align: center;object-fit: cover;padding:5px;color:white;background: rgba(255, 0, 0, 0.5);width:100%;z-index: 1001;"
          class="align-items-center mdfont-resp font-weight-bold"
          :class="{'macWidth' : isMac}"
        >
          <i class="fa fa-circle mr-2 record-button-fade" /> RECORDING IN PROGRESS
        </div>
      </div>

      <!-- Question -->
      <Question 
        v-if="!loading"
        :key="selectedQuestionIndex"
        :question="selectedQuestion" 
        v-bind="{recordStart, retriesLeft, videoQuestionWatched, attempt, allowedPreparationTime, viewingQuestionVideo }"
      />
      <div
        v-else 
        class="d-flex flex-column justify-content-center align-self-center my-auto p-1 p-md-2 mobH" 
        style="background:#757474; color:#fff;height:calc(40% - 20px);text-align: center;"
      >
        Loading question...
      </div>
    </div>

    <div
      v-if="loading === false"
      class="bottom-navigation-container"
    >
      <div class="d-flex flex-column align-items-center justify-content-center">
        <Time
          v-bind="{
            allowedDecideTime,
            allowedPreparationTime,
            allowedRecordingTime,
            allowedPressureWatchVideoQuestionTime,
            freezeVideoQuestionPointerEvents,
            blobRecording,
            candidateIsPreparing, 
            isPractiseQuestion, 
            isScenario,
            numberUnanswered,
            recordStart,
            retriesLeft,
            showDecideTimer,
            viewingQuestionVideo
          }"
        />
        <RecordButtons
          v-bind="{
            allowedPreparationTime,
            blobRecording,
            candidateIsPreparing,
            isPractiseQuestion, 
            isScenario, 
            numberUnanswered,
            recordStart,
            retriesLeft,
            showStop, 
            stream,
            viewingQuestionVideo
          }"
          v-on="{
            beginScenarioQuestions,
            goToPrepTimeFromVideoQuestion, 
            resetVideo, 
            startRecord, 
            stopRecord,
            stopVid,
            uploadCurrentVideoAndGoToNextQuestion,
          }"
        />
      </div>
    </div>
    <div
      v-else
      class="bottom-navigation-container"
    >
      <div class="d-flex flex-column align-items-center justify-content-center">
        <mdb-btn
          size="sm"
          title="START RECORDING"
          alt="START RECORDING"
          class="btnSmall"
          color="primary"
          disabled
          style="width:160px;outline:none !important;padding-left: 1rem !important;padding-right: 1rem !important;"
        >
          <i class="fa fa-circle" /> START RECORDING
        </mdb-btn>
      </div>
    </div>

    <!--failed to upload-->
    <mdb-modal
      id="failedModal"
      :show="failedModal"
      @close="failedModal = false"
    >
      <mdb-modal-header class="px-2 py-1 d-flex align-items-center">
        <mdb-modal-title>
          <div
            style="padding:13px 0px 13px 0px;margin-left:-10px;"
            class="logoLeft"
            :class="{ 'logoLeft': mobileView }"
          >
            <div class="ml-0 ml-md-3 ml-lg-4 nav-colour p-2" style="border-radius:5px;">
              <img 
                :src="branding.Logo"
                style="height:10vw; min-height:40px;max-height:40px;display: block;margin: auto;"
                title="Logo"
                alt="Logo"
              >
            </div>
          </div>
        </mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <p class="my-4 grey-text" style="font-size:1rem;text-align:center;">
          <i class="fa fa-exclamation-triangle primary-colour" style="text-align: center;" /> 
          Error! Please refresh and check if your answer has been uploaded. If not you will need to answer again!
        </p>
      </mdb-modal-body>
    </mdb-modal>
    <!--used by mutation observer to disable ability to close accessiblility toolbar-->
    <div id="locked-page" style="display:none;" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import UploadFile from '@/upload'
import onewayService from "@/api-services/oneway.service"
import Question from "./question.vue"
import RecordButtons from "./recordButtons.vue"
import Time from "./time.vue"
import Playback from "./playback.vue"
import Log from "@/methods/logging.js"

export default {
	components: {
		Question,
		RecordButtons,
		Time,
		Playback
	},
	data () {
		return {
			blockTotal: 0,
			currentBlock: 0,
			attempt: 1,
			blobRecording: null,
			isPlaying: false,
			candidateIsPreparing: false,
			loading: { isLoading: false, message: '' },
			recordChunks: [],
			recordStart: false,
			recordVideo: true,
			recorder: null,
			stopButtonPressed: false,
			stream: null,
			allowedRecordingTime: -1,
			allowedPressureWatchVideoQuestionTime: 30,
			pressureWatchVideoQuestionTimeInterval: null,
			tl: 0,
			retriesLeft: -100,
			prepTimeInterval: null,
			prepDecideTimeInterval: null,
			allowedPreparationTime: 300,
			allowedDecideTime: 300,
			timer: null,
			uploadStarted: false,
			uploadSuccess: false,
			viewingQuestionVideo: false,
			mobileView: false,
			windowHeight: 0,
			windowWidth: 0,
			recordingOn: false,
			failedModal: false,
			selectedQuestionIndex: 0,
			videoQuestionWatched: false,
			qTime: 0,
			iOS: false,
			showStop: false,
			face: 'user',
			isMac: false,
			showDecideTimer: false,
			hasCheckedLockInterview: false,
			freezeVideoQuestionPointerEvents: false,
			cameraOn: true,
			isTablet: false
		}
	},
	computed: {
		...mapGetters(["branding", "candidateQuestions", "position", "job", "invitationCode", "initialUnansweredCount"]),
		numberOfAnswerableQuestionsBeforeSelected() {
			var ordinal = this.selectedQuestion.Ordinal;
			return ordinal + 1 - this.candidateQuestions.slice(0, ordinal).filter(q => q.IsPractiseQuestion || q.ScenarioTitle !== "N/A").length;
		},
		isPractiseQuestion() {
			return this.candidateQuestions[this.selectedQuestionIndex].IsPractiseQuestion;
		},
		dateAnswered() {
			function addZero(i) {
				if (i < 10) {
					i = "0" + i;
				}
				return i;
			}
			try {
				var date = new Date(this.selectedQuestion.DateAnswered);
				return `${date.getUTCHours()}:${addZero(date.getUTCMinutes())} on ${date.getUTCDate()} ${new Intl.DateTimeFormat("en-US", { month: "long" }).format(date)} ${date.getUTCFullYear()}`;
			}
			catch {
				return "";
			}
		},
		numberUnanswered() {
			return this.candidateQuestions.filter(q => !q.IsPractiseQuestion && q.ScenarioTitle === "N/A").filter(q => q.HasAnswered === false).length;
		},
		numberOfAnswerableQuestions() {
			return this.candidateQuestions.filter(q => !q.IsPractiseQuestion).filter(q => q.ScenarioTitle === "N/A").length;
		},
		selectedQuestion() {
			var question = this.candidateQuestions[this.selectedQuestionIndex];
			return { ...question };
		},
		hasAnswered() {
			return this.selectedQuestion.HasAnswered === true;
		},
		isScenario() {
			return this.selectedQuestion.ScenarioTitle !== "N/A";
		}
	},
	watch: {
		selectedQuestionIndex() {
			if (this.selectedQuestionIndex > 0) {
				this.lockInterview()
			}
			this.attempt = 1;
			this.resetVideo(false);
			this.$store.commit("INCREASE_POSITION");
		},
		async allowedPreparationTime() {
			if (this.allowedPreparationTime === 0) {
				Log.debug("Preperation time has expired starting to record for question " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
				try {
					this.startRecord();
				} catch (e) {
					await this.turnOnCamera()
					this.startRecord();
				}
			}
		},
		allowedDecideTime() {
			if (!this.isPractiseQuestion) {
				if (this.allowedDecideTime === -1) {
					//Log.debug("Timer has expired for " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
					this.showDecideTimer = false;
					
					// final question
					
					if (this.numberUnanswered === 1) {
						this.uploadCurrentVideoAndGoToNextQuestion();
						this.stopVid();
					}
					else {
						if (this.retriesLeft === 0) {
							this.uploadCurrentVideoAndGoToNextQuestion();
							this.stopVid();
						}
						else {
							//Log.debug("Retries Remaining for " + this.selectedQuestionIndex + " - " + this.retriesLeft + " and moving onto the next question", 0, this.invitationCode, "InvitationCode")
							this.prepareUpload();
							this.changeVideoIndex();
						}
					}
				}
			}
		},
		allowedRecordingTime() {
			if (this.allowedRecordingTime < 0) {
				this.stopRecord();
				//if (this.retriesLeft === 0) {
				//	this.prepareUpload();
				//	this.changeVideoIndex();
				//}				
			}
		},
		allowedPressureWatchVideoQuestionTime () {
			if (this.allowedPressureWatchVideoQuestionTime === -1) {
				this.viewingQuestionVideo = false
				this.initialisePrepTime()
				clearInterval(this.pressureWatchVideoQuestionTimeInterval)
				this.allowedPressureWatchVideoQuestionTime = 30
			}
		}
	},
	//beforeMount () {
	//var reciteMeToolBarVisible = document.getElementById('recite-toolbar-button-reset');
	//if (reciteMeToolBarVisible !== null) {
	//this.flagReciteMeToolBarAsActive();
	//}
	//},
	mounted() {
		if (this.isPractiseQuestion || this.isScenario) {
			this.allowedPreparationTime = 100000000;
		}
		else {
			this.allowedPreparationTime = this.job.ReadingTime;
		}
		if (this.isScenario) {
			this.allowedRecordingTime = 30;
		}
		else {
			this.allowedRecordingTime = this.selectedQuestion.TimeLimit;
		}
		if (this.job.PressuredRetry) {
			if (this.job.PressuredRetryTime === 99) {
				this.allowedDecideTime = this.selectedQuestion.TimeLimit;
			} else {
				this.allowedDecideTime = this.job.PressuredRetryTime;
			}
		}
		else {
			this.allowedDecideTime = -2;
		}
		this.prepareQuestion();
		var media = this.$refs.videoRec;
		media.addEventListener("ended", this.stopVid);

		var si = this.selectedQuestionIndex;
		this.candidateQuestions.forEach(q => {
			if (si === q.Ordinal) {
				this.tl = q.TimeLimit;
			}
		});
	},
	created() {
		this.windowHeight = window.innerHeight;
		this.windowWidth = window.innerWidth;
		if (/mobile/i.test(navigator.userAgent)) {
			if (/ipad|iPad|tablet/i.test(navigator.userAgent)) {
				this.mobileView = false;
			}
			else {
				this.mobileView = true;
			}
		}
		else {
			this.mobileView = false;
		}
		this.iOS = ["iPhone", "iPad"].indexOf(navigator.platform) >= 0;
		this.isMac = navigator.platform.indexOf("Mac") > -1;

		if (navigator.userAgent.includes('Mac')) {
			this.isMac = true;
		}

		if (navigator.userAgent.includes('iPhone')) {
			this.iOS = true;
		}

		if (navigator.userAgent.includes('iPad')) {
			this.iOS = true;
			this.isTablet = true;
		}

		if (navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform)) {
			this.isTablet = true;
		}

		if (this.isMac) {
			this.iOS = true;
		}
	},
	destroyed() {
		this.turnOffCamera();
	},
	methods: {
		flagReciteMeToolBarAsActive () {
			onewayService.flagReciteMeToolBarAsActive(this.invitationCode).then(() => { })
		},
		showHideMyCamera () {
			if (this.cameraOn) {
				this.cameraOn = false 
			} else {
				this.cameraOn = true
			}
		},
		async lockInterview () {
			if (this.hasCheckedLockInterview == false && this.job.LockInterviewAfterFirstAttempt) {
				await onewayService.lockInterview(this.invitationCode)
				this.hasCheckedLockInterview = true
			}
		},
		beginScenarioQuestions () {
			//Log.trace("Beginning scenario questions", 0, this.invitationCode, "InvitationCode")
			this.changeVideoIndex()
			this.candidateIsPreparing = false
			this.$nextTick(() => {
				this.resetVideo(false)
			})
			clearInterval(this.prepTimeInterval)
			clearInterval(this.prepDecideTimeInterval)
		},
		async uploadCurrentVideoAndGoToNextQuestion () {
			if (!this.isPractiseQuestion && !this.isScenario) {
				//Log.debug("Starting to upload video for question " + this.selectedQuestionIndex + " and moving onto the next question", 0, this.invitationCode, "InvitationCode")
				this.prepareUpload()
			}
			if (this.numberUnanswered === 0) {
				this.$store.commit('SET_TABDESC', 'Progress')
				return
			}
			if (this.isPractiseQuestion) {
				onewayService.answeredPractice(this.invitationCode).then(() => { })
				//Log.trace("Practice question completed", 0, this.invitationCode, "InvitationCode")
			}
			this.$nextTick(() => {
				this.changeVideoIndex()
			})
		},
		changeVideoIndex () {
			this.$forceUpdate()
			if (this.selectedQuestionIndex < this.candidateQuestions.length - 1) {
				this.selectedQuestionIndex += 1
				//Log.debug("Changing video index, old index is: " + (this.selectedQuestionIndex - 1) + " new index is: " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
			} else {			
				//this triggers after the final question
				//Log.debug("Final question complete", 0, this.invitationCode, "InvitationCode")
				this.stopRecord()
			}
		},
		async resetVideo(retrying) {
			//Log.trace("Reset video", 0, this.invitationCode, "InvitationCode")
			if (retrying) {
				onewayService.candidateQuestionRetry(this.invitationCode, this.selectedQuestion.QuestionId).then(async () => {
				})
			}

			this.recordingOn = false;
			this.showDecideTimer = false;
			this.viewingQuestionVideo = false;
			this.uploadSuccess = false;
			this.recordStart = false;
			if (this.isPractiseQuestion || this.isScenario) {
				this.allowedPreparationTime = 100000000;
			}
			else {
				if (this.job.ReadingTime > 0) {
					this.allowedPreparationTime = this.job.ReadingTime;
				}
				else {
					this.allowedPreparationTime = -1;
				}
				if (this.job.PressuredRetry) {
					if (this.job.PressuredRetryTime === 99) {
						this.allowedDecideTime = this.selectedQuestion.TimeLimit;
					} else {
						this.allowedDecideTime = this.job.PressuredRetryTime;
					}
				}
				else {
					this.allowedDecideTime = -2;
				}
			}
			if (this.isScenario) {
				this.allowedRecordingTime = 30;
			}
			else {
				this.allowedRecordingTime = this.selectedQuestion.TimeLimit;
			}
			this.stopButtonPressed = false;
			this.stream = null;
			this.blobRecording = null;
			this.recordChunks = [];
			var video = this.$refs.videoQuestion
			video.setAttribute("controls", true);
			this.stopVid()

			if (this.job.RetryCount === 0 || this.isPractiseQuestion) {
				this.retriesLeft = 1000000;
			}
			else {
				this.retriesLeft = (this.job.RetryCount - this.attempt) + 1;
			}
			
			await this.turnOnCamera()
			this.prepareQuestion()
		},
		async startRecord() {
			//Log.debug("Starting to record question index:" + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
			this.showStop = false;
			if (!this.isPractiseQuestion) {
				setTimeout(() => {
					this.showStop = true;
				}, 10000);
			}
			else {
				this.showStop = true;
			}
			this.candidateIsPreparing = false;
			clearInterval(this.prepTimeInterval);
			clearInterval(this.prepDecideTimeInterval);
			clearInterval(this.timer);
			if (this.isPractiseQuestion || this.isScenario) {
				this.allowedPreparationTime = 100000000;
			}
			else {
				if (this.job.ReadingTime > 0) {
					this.allowedPreparationTime = this.job.ReadingTime;
				}
				else {
					this.allowedPreparationTime = -1;
				}
				if (this.job.PressuredRetry) {
					if (this.job.PressuredRetryTime === 99) {
						this.allowedDecideTime = this.selectedQuestion.TimeLimit;
					} else {
						this.allowedDecideTime = this.job.PressuredRetryTime;
					}
				}
				else {
					this.allowedDecideTime = -2;
				}
			}
			this.recordingOn = true;
			this.timer = setInterval(() => {
				this.allowedRecordingTime--;
				if (this.allowedRecordingTime < 0) {
					this.stopRecord();
					clearInterval(this.timer);
				}
			}, 1000);
			this.stopButtonPressed = false;
			this.recorder = null;
			this.recorder = new MediaRecorder(this.stream);
			this.recorder.ondataavailable = event => {
				this.recordChunks.push(event.data);
			};
			this.recorder.onstop = () => {
				//Log.debug("Stopped Recording for " + this.selectedQuestionIndex , 0, this.invitationCode, "InvitationCode")
				if (this.job.PressuredRetry && !this.isPractiseQuestion) {
					this.showDecideTimer = true;
					this.initialiseDecideTime();
				}
				this.attempt += 1;
				this.stopButtonPressed = true;
				this.recordStart = false;
				this.viewingQuestionVideo = false;
				this.isPlaying = true;
				clearInterval(this.timer);
				this.$nextTick(() => {
					try {
						this.blobRecording = null;
						this.blobRecording = new Blob(this.recordChunks, { type: "video/mp4" });

						try {
							this.stopTracks();
						}
						catch (err) {
							//Log.error("An error occurred when trying to stop tracks: " + err.toString(), 0, this.invitationCode, "InvitationCode")
						}
					
						var URL = window.URL || window.webkitURL;
						this.$refs.videoRec.src = URL.createObjectURL(this.blobRecording);
						this.$refs.videoRec.load();
						let media = this.$refs.videoRec;
						media.muted = !media.muted;					
						if (this.job.AllowRetries === false && this.isPractiseQuestion === false) {
							media.removeAttribute("controls");
						}
						setTimeout(() => {
							media.pause();
							media.muted = !media.muted;
						}, 250);
						this.isPlaying = !media.paused;
					}
					catch (err) {
						//Log.error("An error occurred when trying to create the blob recording: " + err.toString(), 0, this.invitationCode, "InvitationCode")
					}
				});
			};
			this.recorder.start(1000);
			this.recordStart = true;
		},
		stopRecord() {
			try {
				this.recorder.stop();
			}
			catch (err) {
				//Log.error("An error occurred when trying to stop the recorder: " + err.toString(), 0, this.invitationCode, "InvitationCode")
			}
			this.turnOffCamera();
			this.cameraOn = true;
		},
		async turnOnCamera() {
			this.loading = true
			console.log('initialising camera')
			this.stream = null;
			try {
				if (this.job.AudioOnly === 0) {
					if (this.mobileView || (this.iOS && !this.isTablet)) {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 280 } }
								],
								facingMode: { exact: this.face  }
							},
							audio: true
						});
					}
					else if (this.isTablet) {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 280 } }
								],
								facingMode: { exact: this.face },
							},
							audio: true
						});
					}
					else {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 640 } }
								]
							},
							audio: true
						});
					}
				}
				else {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: false,
						audio: true
					});
				}
				this.$refs.video.srcObject = this.stream;
				console.log('camera initialised')
				this.loading = false
			}
			catch (err) {
				Log.error("An error occurred when trying to turn on the camera: " + err.toString(), 0, this.invitationCode, "InvitationCode")
				this.loading = false
				this.turnOffCamera()
			}
		},
		async turnOffCamera() {
			try {
				if (this.stream != null) {
					this.stream.getTracks().forEach(track => track.stop())
				}
			}
			catch (err) {
				Log.error("An error occurred when trying to turn off the camera: " + err.toString(), 0, this.invitationCode, "InvitationCode")
				alert("Sorry, there has been an issue with your camera. Please refresh your browser and resume your interview. Depending on progress you may have to answer some questions again.")
			}
		},
		initialisePrepTime() {
			this.candidateIsPreparing = true;
			if (this.isPractiseQuestion) {
				this.turnOnCamera();
			}
			clearInterval(this.prepTimeInterval);
			clearInterval(this.prepDecideTimeInterval);
			this.prepTimeInterval = setInterval(() => {
				this.allowedPreparationTime -= 1;
			}, 1000);
			if (this.job.RetryCount === 0 || this.isPractiseQuestion) {
				this.retriesLeft = 1000000;
			}
			else {
				this.retriesLeft = (this.job.RetryCount - this.attempt) + 1;
				//Log.trace("Initialising prep time, retries left: " + this.retriesLeft, 0, this.invitationCode, "InvitationCode")
			}
		},
		initialiseDecideTime() {
			//Log.debug("Pressured Retry = Initialising decide time: Question Index:" + this.selectedQuestionIndex , 0, this.invitationCode, "InvitationCode")
			this.candidateIsPreparing = true;
			if (this.isPractiseQuestion) {
				this.turnOnCamera();
			}
			clearInterval(this.prepTimeInterval);
			clearInterval(this.prepDecideTimeInterval);
			this.prepDecideTimeInterval = setInterval(() => {
				this.allowedDecideTime -= 1;
			}, 1000);
		},
		initialisePressureWatchVideoQuestion() {
			//Log.debug("Pressured Video Question = Initialising Pressure Watch Video Question: Question Index:" + this.selectedQuestionIndex , 0, this.invitationCode, "InvitationCode")
			this.pressureWatchVideoQuestionTimeInterval = setInterval(() => {
				this.allowedPressureWatchVideoQuestionTime -= 1;
			}, 1000);
		},
		goToPrepTimeFromVideoQuestion() {
			if (this.job.RetryCount === 0 || this.isPractiseQuestion) {
				this.retriesLeft = 1000000;
			}
			else {
				this.retriesLeft = (this.job.RetryCount - this.attempt) + 1;
			}
			this.videoQuestionWatched = true;
			this.viewingQuestionVideo = false;
			this.prepareQuestion();
		},
		goToPrepTimeFromVideoQuestionScenario() {
			this.selectedQuestion.ScenarioTitle = "N/A";
			if (this.job.RetryCount === 0 || this.isPractiseQuestion) {
				this.retriesLeft = 1000000;
			}
			else {
				this.retriesLeft = (this.job.RetryCount - this.attempt) + 1;
			}
			this.videoQuestionWatched = true;
			this.viewingQuestionVideo = false;
			this.prepareQuestion();
		},
		async prepareQuestion () {
			if (this.selectedQuestion.IsPractiseQuestion && this.position >= 1 || this.selectedQuestion.HasAnswered) {
				//this will skip the practice question in the event the user has already answered some questions and is returning
				//Log.trace("Skipping practice question, going to question index  : " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
				this.changeVideoIndex()
				return
			}
			if (this.selectedQuestion.IsVideo && !this.videoQuestionWatched) {
				
				//VIDEO QUESTIONS
				
				clearInterval(this.prepDecideTimeInterval)
				if (this.job.PressuredRetry) {
					if (this.job.PressuredRetryTime === 99) {
						this.allowedDecideTime = this.selectedQuestion.TimeLimit;
					} else {
						this.allowedDecideTime = this.job.PressuredRetryTime;
					}
				} else {
					this.allowedDecideTime = -2
				}
				if (this.attempt !== 1 && this.job.AllowRetries) {
					this.viewingQuestionVideo = false
					this.initialisePrepTime()
					return
				} else {
					this.viewingQuestionVideo = true
					if (this.job.PressureWatchVideoQuestion) {
						this.initialisePressureWatchVideoQuestion()
					}
				}
				this.$nextTick(() => {
					var video = this.$refs.videoQuestion
					video.src = this.selectedQuestion.VideoUrl
					video.onended = () => {
						if (this.job.PressureWatchVideoQuestion) {
							this.freezeVideoQuestionPointerEvents = false
							this.goToPrepTimeFromVideoQuestion()
						}
					}
					video.onplay = () => {
						if (this.job.PressureWatchVideoQuestion) {
							video.removeAttribute("controls");
							clearInterval(this.pressureWatchVideoQuestionTimeInterval)
							this.allowedPressureWatchVideoQuestionTime = 30
							this.freezeVideoQuestionPointerEvents = true
						}
					}
				})
				this.stopTracks()
			} else if (!(this.isPractiseQuestion == true && this.position > 0)) {
				
				//FIRST TIME PRACTISE QUESTION OR NORMAL QUESTION
				
				this.videoQuestionWatched = false
				this.initialisePrepTime()
				this.viewingQuestionVideo = false
				var video = this.$refs.videoQuestion
				video.src = null
			}
			if (this.isScenario) {
				this.allowedRecordingTime = 30
			} else {
				this.allowedRecordingTime = this.selectedQuestion.TimeLimit
			}
			this.$forceUpdate()
		},
		stopTracks() {
			if (this.recorder !== null) {
				if (this.recorder.stream != null) {
					this.recorder.stream.getTracks().forEach(track => track.stop());
				}
			}
		},
		stopVid() {
			var media = this.$refs.videoRec;
			media.pause();
			media.currentTime = 0;
			this.isPlaying = false;
		},
		async prepareUpload () {
			var data = this.blobRecording;
			var questionId = this.selectedQuestion.QuestionId;
			var vData = {
				InvitationCode: this.invitationCode,
				VideoDuration: this.recordChunks.length + 1,
				GoCandidateQuestionId: this.selectedQuestion.QuestionId
			}
			this.recordChunks = [];
			this.$store.commit("CHANGE_HAS_ANSWERED", { QuestionId: questionId });
			this.$store.commit("CHANGE_UPLOAD_STATE", { QuestionId: questionId, UploadState: "PreparingUpload" });
			this.$store.commit("ADD_REMOVE_TEMP_BLOB", { isAdding: true, blob: this.blobRecording, questionId: questionId });
			this.uploadStarted = true;
			//Log.debug("Preparing to Upload question: " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
			try {
				var uploadSuccess = await UploadFile.uploadVideo(0, data, vData, this.updateUploadProgress);
				if (uploadSuccess) {
					//Log.debug("Upload Success : " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
					this.$store.commit("UPDATE_UPLOAD_PERCENTAGE", { QuestionId: questionId, BlockTotal: null });
					this.$store.commit("CHANGE_UPLOAD_STATE", { QuestionId: questionId, UploadState: "Finalizing" });
					this.$store.commit("ADD_REMOVE_TEMP_BLOB", { isAdding: false, blob: null, questionId: questionId });
				}
				else {
					//Log.error("Upload Failure : " + this.selectedQuestionIndex, 0, this.invitationCode, "InvitationCode")
					throw "Upload Failed";
				}
			} catch (err) {
				this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: questionId, UploadState: 'Failed' })
				//await Log.error("An error occurred when trying to upload the question: " + this.selectedQuestionIndex + " - " + err.toString(), 0, this.invitationCode, "InvitationCode")
				this.$store.commit('CHANGE_UPLOAD_STATE', { QuestionId: questionId, UploadState: 'Failed' })
			}
		},
		updateUploadProgress(blockTotal, questionId, error = null) {
			if (blockTotal === "Failed") {
				if (error != null) {
					console.log("An error occurred when trying to Update the upload progress for question: " + this.selectedQuestionIndex + " - " + error.toString())
					//	Log.error("An error occurred when trying to Update the upload progress for question: " + this.selectedQuestionIndex + " - " + error.toString(), 0, this.invitationCode, "InvitationCode")
				}
				this.$store.commit("CHANGE_UPLOAD_STATE", { QuestionId: questionId, UploadState: "Failed" });
				throw "Upload Failed";
			}
			this.$store.commit("CHANGE_UPLOAD_STATE", { QuestionId: questionId, UploadState: "Uploading" });
			this.$store.commit("UPDATE_UPLOAD_PERCENTAGE", { QuestionId: questionId, BlockTotal: blockTotal + 1 });
		},
		async toggleCamera() {
			if (this.face === "user") {
				this.face = "environment";
			}
			else {
				this.face = "user";
			}
			try {
				if (this.job.AudioOnly === 0) {
					if (this.mobileView || (this.iOS && !this.isTablet)) {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 280 } }
								],
								facingMode: { exact: this.face  }
							},
							audio: true
						});
					}
					else if (this.isTablet) {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 280 } }
								],
								facingMode: { exact: this.face }
							},
							audio: true
						});
					}
					else {
						this.stream = await navigator.mediaDevices.getUserMedia({
							video: {
								advanced: [
									{ width: { exact: 640 } }
								]
							},
							audio: true
						});
					}
				}
				else {
					this.stream = await navigator.mediaDevices.getUserMedia({
						video: false,
						audio: true
					});
				}
				this.$refs.video.srcObject = this.stream;
				console.log('camera initialised')
				this.loading = false
			}
			catch (err) {
				//Log.error("An error occurred when trying to toggle the camera: " + err.toString(), 0, this.invitationCode, "InvitationCode")
				this.turnOffCamera()
			}
		}
	},
	beforeUnmount() {
		this.recordChunks = [];
		//Log.error("Recording page reload - device issue or candidate refresing?", 0, this.invitationCode, "InvitationCode")
		window.location.href = "/?inv=" + this.invitationCode + "&memory=1";
	}
}
</script>

<style lang="scss" scoped>
  .recite-close {
	display:none !important;
  }
</style>

<style lang="scss">
  .tabletWidth {
    height: 480px !important;
	border-radius: 0px !important;
    position: absolute;
    width: 640px !important;
    border: solid 3px white;
	top: auto !important;
  }
  .fullW {
    width:-webkit-fill-available;
  }
  .silhouetteMargin {
    margin-bottom: 40px;
  }
  .audioOnly {
    height:58% !important;
  }
  .audioOnlyPosition {
    top:0px;
  }
  .hide-scrollbar
  {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .playBtnMargin {
    margin-top: -25px !important;
  }
  .btn {
    border: none;
  }
  @media (min-width: 993px) {
    .qSize {
      font-size: 1.2rem !important;
    }
    .mob-pr-col {
      color: var(--lk-color) !important;
    }
  }
  @media (min-width:599px) and (max-width:992px) {
    .col-sm-7 {
      flex: 0 0 66.5% !important;
      max-width: 66.5% !important;
    }
  }
  @media (max-width: 992px) {
    .mob-pr-col {
      color: var(--pr-color) !important;
    }
  }
  @media (max-width: 599px) {
    .col-sm-7 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .mtop20 {
      margin-top:-20px;
    }
  }
  .heightVh {
   height: 100hv !important;
  }
  @media (max-width: 1280px) {
    .deskPositioning {
		width: 640px !important;
    }
  }
  @media screen and (max-width: 1280px) {
    .deskPositioning {
		width: 100% !important;
    }
	.eyeEye {
		bottom:10px !important;
		right: 1px !important;
	}
  }
  @media (max-width: 761px) {
	.eyeEye {
		bottom:8px !important;
		right: 1px !important;
	}
  }
  .macWidth {
	width: 100% !important;
  }
</style>
